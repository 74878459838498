import {useSportStore} from '@/stores/common/sport.js'
import {api} from '@/plugins/axios'
import {echo} from '@/plugins/echo'
import {defineStore} from 'pinia'
import {useDrawerStore} from '@/stores/drawer'

export const usePregameStore = defineStore('pregame', () => {
  const sportStore = useSportStore()
  const drawerStore = useDrawerStore()

  async function fetch() {
    sportStore.fetching = true
    const {data} = await api.get('pregames')
    sportStore.games = new Map(data.data?.map(game => [game.id, game]))
    drawerStore.games = data.data
    sportStore.fetching = false
  }

  async function subscribe() {
    echo.channel('pregame-event').listen('.updated', async () => {
      await fetch()
    })
  }

  async function unsubscribe() {
    echo.leaveChannel('pregame-event')
  }

  return {
    fetch,
    subscribe,
    unsubscribe,
  }
})
